import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/base';
import './plugins/chartist';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import VueQuillEditor from 'vue-quill-editor';
import ApiService from '@/services/api.service';
import localStoragePrefix from './config';
import TOKEN from './store/constants';

// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor);

Vue.config.productionTip = false;

// API service init
ApiService.init();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  data: {
    localStoragePrefix,
    TOKEN,
  },
  render: (h) => h(App),
}).$mount('#app');
