import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Index"),
      children: [
        // Dashboardx
        {
          name: "الرئيسية",
          path: "",
          component: () => import("@/views/Dashboard/index"),
        },
        // Pages
        {
          name: "RTL",
          path: "rtl",
          component: () => import("@/views/pages/Rtl"),
        },
        {
          name: "Timeline",
          path: "timeline",
          component: () => import("@/views/pages/Timeline"),
        },
        {
          name: "الملف الشخصي",
          path: "pages/user",
          component: () => import("@/views/pages/Profiles/UserProfile"),
        },
        {
          name: "إضافة مستخدم",
          path: "/add",
          component: () => import("@/views/pages/Profiles/Add"),
        },
        {
          name: "بيانات محاضر",
          path: "/instructorData",
          component: () => import("@/views/pages/InstrucrorInfo"),
        },
        {
          name: "الإعدادات",
          path: "setting",
          component: () => import("@/views/pages/Setting/index"),
        },
        {
          name: "كبونات",
          path: "coupons",
          component: () => import("@/views/pages/Coupons/index"),
        },
        {
          name: "المميزات",
          path: "features",
          component: () => import("@/views/pages/Features/index"),
        },
        {
          name: "البلاد",
          path: "countries",
          component: () => import("@/views/pages/Countries/index"),
        },
        {
          name: "الوسوم",
          path: "tags",
          component: () => import("@/views/pages/Tags/index"),
        },
        {
          name: "مميزات المنتجات",
          path: "Courses-features",
          component: () => import("@/views/pages/CourseFeatures/index"),
        },
        {
          name: "الجلسات",
          path: "sessions",
          component: () => import("@/views/pages/Sessions/index"),
        },
        {
          name: "الدورات",
          path: "courses",
          component: () => import("@/views/pages/CoursesPage/Index"),
        },
        {
          name: "محاور",
          path: "/sections/add/:id",
          component: () => import("@/views/pages/CoursesPage/comp/sections"),
        },
        {
          name: "محاضرات",
          path: "/lectures/add/:id",
          component: () => import("@/views/pages/CoursesPage/comp/Lectures"),
        },
        {
          name: "إختبارات",
          path: "/challenges/add/:id",
          component: () => import("@/views/pages/CoursesPage/comp/Challenges"),
        },
        {
          name: "مقاييس",
          path: "indicator",
          component: () => import("@/views/pages/IndicatorPage/index"),
        },
        {
          name: "مجموعات",
          path: "/groups/add/:id",
          component: () => import("@/views/pages/IndicatorPage/comp/Groups"),
        },
        {
          name: "أسئلة",
          path: "/questions/add/:id",
          component: () => import("@/views/pages/IndicatorPage/comp/Questions"),
        },
        {
          name: "نتائج",
          path: "/results/add/:id",
          component: () => import("@/views/pages/IndicatorPage/comp/Results"),
        },
        {
          name: "مقالات",
          path: "/posts",
          component: () => import("@/views/pages/Posts/index"),
        },
        {
          name: "الإعلانات",
          path: "/advertisement",
          component: () => import("@/views/pages/Advertisement/index"),
        },
        {
          name: "الشركاء",
          path: "/partenars",
          component: () => import("@/views/pages/Partenars/index"),
        },
        {
          name: "Notifications",
          path: "components/notifications",
          component: () => import("@/views/component/Notifications"),
        },
      ],
    },
  ],
});
