<template>
  <router-view
    :dir="pointto"
    :direction="pointto"
    :style="'direction: ' + pointto"
  />
</template>

<script>
export default {
  name: "App",
  data: () => ({
    pointto: "rtl",
  }),
  beforeCreate() {
    this.$vuetify.rtl = true;
  },
};
</script>
