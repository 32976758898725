// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';

// Store functionality
import actions from './actions';
import getters from './getters';
// import modules from './modules';
import mutations from './mutations';
import state from './state';
import userModule from './user.module';

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  // modules,
  userModule,
  mutations,
  state,
  modules: {
    userModule,
  },
});

export default store;
